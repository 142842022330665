:root {
  --black: #333;
  --swatch-size: clamp(6.25rem, 10vw, 12.5rem);
  --gray: #cccccc;
  --input-width: clamp(12.5rem, 34vw, 35rem);
    --label-offset: clamp(4.5rem, 9vw, 9rem);
}

.App {
  padding-inline: 2vw;
  font-family: "Work Sans", Helvetica, sans-serif;
  text-align: center;
  color: var(--black);
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

h1 {
  margin: 2vw auto 0 auto;
}

p {
  margin: 2vw auto;
}

.inputs,
.lch-input {
  display: flex;
  flex-direction: column;
  position: relative;
  left: -4vw;
}

@media (min-width: 75rem) {
  .lch-input {
      left: 0;
    }
  .inputs {
    display: block;
    columns: 2;
    left: 0;
  }


}

label {
  font-size: clamp(1rem, 2vw, 2rem);
  display: block;
}

.label-text {
  display: inline-block;
  width: var(--label-offset);
  text-align: right;
}

input[type=text] {
  width: var(--input-width);
  font-size: clamp(1rem, 2vw, 2rem);
  padding: 1.2vw;
  border: 3px solid var(--black);
  background: white;
  margin-bottom: 2rem;
  margin-left: 1vw;
  font-family: "Work Sans", Helvetica, sans-serif;
  transition: border-color ease-in .2s, border-radius ease-in .2s, background-color ease-in .2s;
}

.color-input-wrapper {
  width: var(--input-width);
  display: inline-block;
  /* padding: calc(1.2vw + 3px); */
  margin-bottom: 2rem;
  margin-left: calc(2.2vw + 16px);
  text-align: left;
}

input[type=color] {
  position: relative;
  left: -1.2vw;
}

input:focus {
  border-color: blue;
  border-radius: clamp(.5rem, 1vw, 1rem);;
  outline: none;
  background-color: hsla(60, 100%, 50%, 0.2);
}


.swatch-wrapper {
  --square-size: calc(var(--swatch-size) / 5);
  --half-square: calc(var(--swatch-size) / 10);
  --neg-half-square: calc((var(--swatch-size) / 10) * -1);
  display: inline-block;
  position: relative;
  margin-bottom: 3rem;
  width: var(--swatch-size);
  height: var(--swatch-size);
  background-image:
    linear-gradient(45deg, var(--gray) 25%, transparent 25%),
    linear-gradient(-45deg, var(--gray) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--gray) 75%),
    linear-gradient(-45deg, transparent 75%, var(--gray) 75%);
  background-size: var(--square-size) var(--square-size);
  background-position:
    0 0,
    0 var(--half-square),
    var(--half-square) var(--neg-half-square),
    var(--neg-half-square) 0px;
}

.swatch-wrapper::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 70%);
}

.swatch {
  display: inline-block;
  width: var(--swatch-size);
  height: var(--swatch-size);
  position: relative;
}

.lch-warning {
  display: block;
  position: absolute;
  margin-top: -2rem;
  width: 100%;
  text-align: center;
}

.gamut-warning {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding-inline-start: var(--label-offset);
  position: relative;
  top: -1rem;
}

footer {
  padding: 3vw;
  line-height: 1.4;
}

footer a {
  color: var(--black);
}
